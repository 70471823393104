import React,{useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './MyProfile.css';

const MyProfile = () => {

    useEffect(()=>{
        document.title= 'About me'
    })

    return (
      <Container className='myprofile-container' as='main'>
        <Row>
          <Col xs='10' md='12' lg='10'>
            <h1>Hi I'm Jordi,</h1>
          </Col>
        </Row>
        <Row>
          <Col xs='10' md='12' lg='10'>
            <h2>I'm a Full-Stack web developer based in Barcelona.</h2>
          </Col>
        </Row>
        <Row as='section'>
          <Col
            xs='12'
            sm='10'
            md='4'
            lg='3'
            as='article'
            className='profile-description'
          >
            <p>
              I’ve always been passionate about programming, starting my journey
              with C# and quickly discovering a love for designing dynamic,
              visually engaging user interfaces through XAML. This sparked my
              transition to full-stack web development, where I thrive on
              creating applications that seamlessly balance functionality and
              design.
            </p>
            <p>
              Over the years, I’ve worked on diverse web applications using
              technologies like React, Vue, Node.js, PHP, Go, Rust, and Python,
              delivering impactful solutions for startups and enterprises.
            </p>
          </Col>
          <Col
            xs='12'
            sm='10'
            md='6'
            lg='7'
            as='article'
            className='profile-main'
          >
            <p>
              As an experienced software developer, I specialize in crafting
              robust, scalable applications by leveraging a versatile skill set
              that spans front-end and back-end technologies. Passionate about
              innovation, I excel in creating responsive, user-friendly
              interfaces with React and Next.js while integrating powerful
              back-end solutions using Node.js or Python among others. My
              projects consistently feature clean code, performance
              optimizations, and scalable architecture.
            </p>
            <p>
              Over my career, I’ve collaborated with cross-functional teams to
              deliver impactful solutions for startups and established
              enterprises. From building end-to-end applications to optimizing
              load times, I take pride in my problem-solving abilities,
              adaptability, and drive for continuous learning. My expertise
              extends to mentoring aspiring developers, ensuring high-quality
              code reviews, and fostering a collaborative team environment.
            </p>
            <p>
              I’m always eager to tackle new challenges and learn cutting-edge
              technologies. With a deep interest in performance optimization and
              scalable architecture, I’m excited about advancing in roles that
              allow me to further explore React, Next.js, Node.js, Cloud and
              A.I. Ultimately, I want to contribute to innovative,
              mission-driven teams and impactful projects that make a difference
              in the world.
            </p>
            <p>
              When I’m not coding, I enjoy traveling, experiencing new cultures,
              and staying healthy and fit. Having lived in the UK for over a
              decade, I’ve come to appreciate the British culture, which holds a
              special place in my heart. In 2024, I moved to Barcelona, and I’m
              excited to continue wherever it takes me.
            </p>
            <p>P.S. I love Mexico too.</p>
          </Col>
        </Row>
        <Row as='section' className='portfolio-link'>
          <Col xs='12'>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://interactive-resume-2753d.web.app/'
            >
              Previous version of my portfolio build with AngularJS
            </a>
          </Col>
        </Row>
      </Container>
    );
}

export default MyProfile; 